import React from "react"
import { Content } from "antd/lib/layout/layout"
import Title from "antd/lib/typography/Title"
import { Breadcrumb, Button } from "antd"
import { Link, navigate } from "gatsby"
import HandleCompanyInfo from "../../../../customComponents/companyInfos/accountList/handleCompanyInfo/HandleCompanyInfo"
import Topbar from "../../../../components/organisms/topbar"
import CustomLayout from "../../../../Layouts/Layout"

function index() {
  const hanldeWithDrawMoney = () => {
    navigate("/withdraw-money")
  }
  return (
    <div>
      <Topbar />
      <CustomLayout
        selectedPage={
          <Content>
            <div
              className="site-layout-background"
              style={{
                padding: 24,
                minHeight: 200,
              }}
            >
              <Breadcrumb separator={">"}>
                <Breadcrumb.Item>
                  <Link to="/home">HOME</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/company-info">企業情報</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/company-info/register/handleCompanyInfo/create">
                    アカウント追加
                  </Link>
                </Breadcrumb.Item>
              </Breadcrumb>
              <div className="flex items-center justify-between">
                <Title
                  level={3}
                  style={{
                    marginBottom: "1rem",
                    marginTop: "1rem",
                    padding: "20px 25px",
                  }}
                >
                  企業情報 - アカウント追加
                </Title>
                <Button className="mr-10" onClick={hanldeWithDrawMoney}>
                  退会申請
                </Button>
              </div>
            </div>
            <HandleCompanyInfo />
          </Content>
        }
      />
    </div>
  )
}

export default index
